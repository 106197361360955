<template>
  <b-card-code title="Add New Assign Dial-Up">
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row class="justify-content-center">
          <b-col lg="6" md="6" sm="6">
            <!-- button on both side -->
            <b-input-group>
              <b-form-input v-model="pair_code" placeholder="Enter Pair Code" />
              <b-input-group-append>
                <b-button
                  type="submit"
                  variant="outline-primary"
                  @click.prevent="pairCodeFind"
                >
                  <feather-icon icon="SearchIcon" />
                  Search !
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
        <b-row class="justify-content-center">
          <b-col v-if="this.userData" lg="6" md="6" sm="6">
            <b-card
              align="center"
              bg-variant="transparent"
              border-variant="success"
              class="shadow-none"
              text-variant="black"
              title="User Information "
            >
              <b-card-text>
                <b-list-group v-if="this.userData">
                  <b-list-group-item v-if="this.userData.name"
                    >Name : {{ this.userData.name }}</b-list-group-item
                  >
                  <b-list-group-item v-if="this.userData.mobile"
                    >Mobile : {{ this.userData.mobile }}</b-list-group-item
                  >
                  <b-list-group-item v-if="this.userData.email"
                    >Email : {{ this.userData.email }}</b-list-group-item
                  >
                  <b-list-group-item v-if="this.userData.address"
                    >Address : {{ this.userData.address }}</b-list-group-item
                  >
                </b-list-group>
              </b-card-text>
            </b-card>
          </b-col>
        </b-row>
        <b-row v-if="this.userData">
          <input
            v-model="this.userData.id"
            type="hidden"
            class="form-control"
          />
          <b-col md="6">
            <b-form-group label="Package Name" label-for="package_name">
              <validation-provider
                #default="{ errors }"
                name="Package Name"
                rules="required"
              >
                <v-select
                  :options="package_list"
                  :reduce="(val) => val.value"
                  @input="
                    (packageData) => changePackage(package_list, packageData)
                  "
                  placeholder="Select Package"
                  v-model="package_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :clearable="false"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Monthly Bill" label-for="package_price">
              <validation-provider
                #default="{ errors }"
                name="package_price"
                rules="required"
              >
                <b-form-input
                  v-model="package_price"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Monthly Bill"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Router Name" label-for="router_id">
              <validation-provider
                #default="{ errors }"
                name="Router Name"
                rules="required"
              >
                <v-select
                  v-model="router_id"
                  :clearable="false"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="router_list"
                  :reduce="(val) => val.value"
                  input-id="user-role"
                  placeholder="Select Router"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="PPP Secrets Name" label-for="user_name">
              <validation-provider
                #default="{ errors }"
                name="user_name"
                rules="required"
              >
                <b-form-input
                  v-model="user_name"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Enter PPP Secrets Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Connection Nick Name" label-for="title">
              <validation-provider
                #default="{ errors }"
                name="title"
                rules="required"
              >
                <b-form-input
                  v-model="title"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Enter Connection Nick Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Invoice Date" label-for="invoice_date">
              <validation-provider
                #default="{ errors }"
                name="invoice_date"
                rules=""
              >
                <b-form-datepicker
                  id="invoice_date"
                  v-model="invoice_date"
                  class="mb-1"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Invoice Due Date" label-for="next_due_date">
              <validation-provider
                #default="{ errors }"
                name="next_due_date"
                rules=""
              >
                <b-form-datepicker
                  id="next_due_date"
                  v-model="next_due_date"
                  class="mb-1"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Status" label-for="status">
              <validation-provider
                #default="{ errors }"
                name="Status"
                rules="required"
              >
                <b-form-select
                  v-model="status"
                  :options="options"
                ></b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Auto Generate" label-for="autogenerate">
              <validation-provider
                #default="{ errors }"
                name="autogenerate"
                rules=""
              >
                <b-form-checkbox v-model="autogenerate" value="1">
                  Automatic Invoice
                </b-form-checkbox>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-button
              type="submit"
              variant="primary"
              @click.prevent="validationForm"
              class="pull-right"
            >
              Submit
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BFormDatepicker,
  BFormCheckbox,
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BCardGroup,
  BCard,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";
import { required, email } from "@validations";
import useJwt from "@/auth/jwt/useJwt";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {
  CUSTOMER_USER_LIST,
  ISP_PACKAGE_LIST,
  ISP_ROUTER_LIST,
  PAIR_CODE_FIND,
  ROUTER_VIEW,
} from "@core/services/api";
import vSelect from "vue-select";
import { checkRes } from "@core/services/helper";
import Mixin from "@core/services/mixin";
import $ from "jquery";

export default {
  mixins: [Mixin],
  components: {
    BFormCheckbox,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormSelect,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    BCardGroup,
    BCard,
    BCardText,
    BListGroup,
    BListGroupItem,
    BFormDatepicker,
  },
  data() {
    return {
      isp_id: null,
      pair_code: null,
      userData: null,
      user_id: null,
      router_id: null,
      package_id: null,
      package_price: null,
      title: null,
      user_name: null,
      price: null,
      selected: null,
      invoice_date: null,
      next_due_date: null,
      autogenerate: 0,
      package_list: [],
      user_list: [],
      router_list: [],
      emailValue: "",
      name: "",
      required,
      email,
      status: null,
      options: [
        { value: null, text: "Please select status" },
        { value: "1", text: "Active" },
        { value: "0", text: "Inactive" },
      ],
    };
  },
  methods: {
    changePackage(package_price, packageData) {
      if (package_price) {
        for (const package_price of package_price) {
          if (package_price.value == packageData) {
            this.package_price = package_price.price;
            this.package_id = package_price.value;
          }
        }
      }
    },
    packageListData(ispId) {
      this.$http
        .get(
          process.env.VUE_APP_BASEURL + ISP_PACKAGE_LIST + "?isp_id=" + ispId
        )
        .then((res) => {
          this.package_list = res.data.data;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            userData.value = undefined;
          }
        });
    },
    routerListData(ispId) {
      this.$http
        .get(process.env.VUE_APP_BASEURL + ISP_ROUTER_LIST + "?isp_id=" + ispId)
        .then((res) => {
          this.router_list = res.data.data;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            userData.value = undefined;
          }
        });
    },
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          useJwt
            .createCustomerUserName({
              isp_id: this.getIspId(),
              user_id: this.userData.id,
              router_id: this.router_id,
              package_id: this.package_id,
              package_price: this.package_price,
              title: this.title,
              user_name: this.user_name,
              next_invoice_date: this.invoice_date,
              next_due_date: this.next_due_date,
              autogenerate: this.autogenerate,
              status: this.status,
            })
            .then((response) => {
                console.log(response.data)
              if (checkRes(response.data.code)) {
                this.$refs.simpleRules.reset();
                this.title = "";
                this.user_name = "";
                this.package_id = "";
                this.router_id = "";
                this.next_due_date = "";
                this.invoice_date = "";
                this.autogenerate = 0;
                this.$router
                  .push({ name: "isp-user-name-create" })
                  .catch((error) => {
                    this.toastMessage("success", "Assign Dail Up", response);
                  });
              } else {
                  if (response.data.status_message == "due_monthly_payment") {
                      this.$router
                          .push({name: "service-charge-package-list"})
                          .catch((error) => {
                              this.toastMessage("success", "Pay Monthly Bill", response);
                          });
                  } else {
                      this.$router
                          .push({ name: "isp-user-name-create" })
                          .catch((error) => {
                              this.toastMessage("warning", "Assign Dail Up", response);
                          });
                  }
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    pairCodeFind() {
      this.$http
        .get(
          process.env.VUE_APP_BASEURL +
            PAIR_CODE_FIND +
            "pair_code=" +
            this.pair_code
        )
        .then((res) => {
          this.userData = res.data.data;
          if (!checkRes(res.data.code) && !this.userData) {
            this.toastMessage("warning", "Assign Dail Up", res);
          }
        })
        .catch((error) => {
          this.toastMessage("warning", "Assign Dail Up", res);
        });
    },
    getIspId() {
      return $("#ispListId option:selected").val();
    },
  },
  mounted() {
    this.getIspId();
    this.isp_id = $("#ispListId option:selected").val();
    let instance = this;
    $("#ispListId").change(function () {
      this.isp_id = $(this).children("option:selected").val();
      instance.packageListData(this.isp_id);
      instance.routerListData(this.isp_id);
    });
    this.packageListData(this.isp_id);
    this.routerListData(this.isp_id);
  },
};
</script>
